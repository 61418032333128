import SpiritFestival from "../components/landing";
import Footer from "../components/footer";

const Auth=()=>{
    return (
        <div>
            <SpiritFestival/>
            <Footer/>
        </div>
    )
}
export default Auth;