import React, { useState } from "react";

const teamLimits = {
  'Kabaddi (Men)': { min: 7, max: 12 },
  'Volleyball (Men)': { min: 7, max: 12 },
  'Table Tennis (Men)':{min:1,max:1},
  'Table Tennis (Women)':{min:1,max:1},
  'Cricket (Men)':{min:1,max:1},
  'Hockey (Men)':{min:1,max:1},
  'KhoKho (Men)':{min:1,max:1},
  'KhoKho (Women)':{min:1,max:1},
  'Volleyball (Women)':{min:1,max:1},
  'Basketball (Men)':{min:1,max:1},
  'Basketball (Women)':{min:1,max:1},
  'Football (Men)':{min:1,max:1}
  // Add other sports as needed
};

const fixtures = {
  'Kabaddi (Men)': [
    { date: "25/10/2024", time: "9:00-9:45", match: "AIPE vs Jorhat", pool: "A", score:"AIPE 35:34 Jorhat (AIPE won by 1 point)" },
    { date: "25/10/2024", time: "9:45-10:30", match: "NIT AP vs Jagiroad", pool: "A", score: "NIT AP 27:42 Jagiroad" },
    { date: "25/10/2024", time: "10:30-11:15", match: "B.Borooh vs Cotton", pool: "B", score: "B.Borooh 41:21 Cotton" },
    { date: "25/10/2024", time: "11:15-12:00", match: "AIPE vs NIT AP", pool: "A", score: "AIPE 33:19 NIT AP" },
    { date: "25/10/2024", time: "14:00-14:45", match: "Cotton vs DSEU", pool: "B", score:"Cotton 36:28 DSEU" },
    { date: "25/10/2024", time: "14:45-15:30", match: "Jorhat vs Jagiroad", pool: "A", score: "Jorhat 39:14 Jagiroad" },
    { date: "25/10/2024", time: "15:30-16:15", match: "DSEU vs B.Borooh", pool: "B", score: "DSEU 36:42 B.Borooh" },
    { date: "25/10/2024", time: "16:15-17:00", match: "AIPE vs Jagiroad", pool: "A", score: "AIPE 23:42 Jagiroad" },
    { date: "25/10/2024", time: "17:00-17:45", match: "Jorhat vs NIT AP", pool: "A", score: "Jorhat 13:24 NIT AP" },
    { date: "25/10/2024", time: "17:45-18:30", match: "B.Borooh vs IITG", pool: "B", score: "B.Borooh 17:47 IITG" },
    { date: "26/10/2024", time: "9:00-9:45", match: "Cotton vs IITG", pool: "B", score: null },
    { date: "26/10/2024", time: "11:00-11:45", match: "DSEU vs IITG", pool: "B", score: null },
    { date: "26/10/2024", time: "14:00-15:00", match: "Winner of A vs Runner of B", pool: "SF1", score: null },
    { date: "26/10/2024", time: "15:00-16:00", match: "Runner of A vs Winner of B", pool: "SF2", score: null },
    { date: "26/10/2024", time: "17:30-18:30", match: "Winner of SF1 vs Winner of SF2", pool: "Final", score: null },
  ],
  'Volleyball (Men)': [
    { date: "Match 1", time: "8:00-9:30", match: "BBEC vs IIIT Guwahati", pool: "A", court: "1", score:"IIIT Guwahati 2:0 BBEC"},
    { date: "Match 2", time: "8:00-9:30", match: "ADTU vs Cotton", pool: "B", court: "2", score: null },
    { date: "Match 3", time: "9:30-11:00", match: "IIT Patna vs AEC", pool: "C", court: "1", score: null },
    { date: "Match 4", time: "9:30-11:00", match: "Sherubtse vs Jorhat", pool: "D", court: "2", score: null },
    { date: "Match 5", time: "11:00-12:30", match: "IIIT Guwahati vs DSEU", pool: "A", court: "1", score: null },
    { date: "Match 6", time: "11:00-12:30", match: "Cotton vs ADBU Azara", pool: "B", court: "2", score: null },
    { date: "Match 7", time: "15:30-17:00", match: "AEC vs ADBU Tapesia", pool: "C", court: "1", score: null },
    { date: "Match 8", time: "15:30-17:00", match: "DSEU vs BBEC", pool: "A", court: "2", score: null },
    { date: "Match 9", time: "17:00-18:30", match: "ADBU Azara vs ADTU", pool: "B", court: "1", score: null },
    { date: "Match 10", time: "17:00-18:30", match: "Jorhat vs IITG", pool: "D", court: "2", score: null },
    { date: "Match 11", time: "18:30-20:00", match: "ADBU Tapesia vs IIT Patna", pool: "C", court: "1", score: null },
    { date: "Match 12", time: "18:30-20:00", match: "IITG vs Sherubtse", pool: "D", court: "2", score: null },
    { date: "Match 13", time: "8:00-10:00", match: "Winner of A vs Runner of C", pool: "QF1", court: "1", score: null },
    { date: "Match 14", time: "8:00-10:00", match: "Winner of B vs Runner of D", pool: "QF2", court: "2", score: null },
    { date: "Match 15", time: "10:00-12:00", match: "Runner of A vs Winner of C", pool: "QF3", court: "1", score: null },
    { date: "Match 16", time: "10:00-12:00", match: "Runner of B vs Winner of D", pool: "QF4", court: "2", score: null },
    { date: "Match 17", time: "15:00-17:00", match: "Winner of QF1 vs Winner of QF4", pool: "SF1", court: "2", score: null },
    { date: "Match 18", time: "17:00-19:00", match: "Winner of QF2 vs Winner of QF3", pool: "SF2", court: "2", score: null },
    { date: "Match 19", time: "10:00-12:00", match: "Winner of SF1 vs Winner of SF2", pool: "Final", court: "2", score: null },
  ],
  'Table Tennis (Men)':[
  { date: '25/10/24', match: 'NERIST vs GEC Vaishali', time: '9:00-10:30', pool: 'A', court: 'Court 1',score:"NERIST 3:0 GEC Vaishali" },
  { date: '25/10/24', match: 'Tetso vs Tiss', time: '9-10:00', pool: 'B', court: 'Court 2',score:"Walk Over to TISS"  },
  { date: '25/10/24', match: 'GCC vs Cotton', time: '10:30-12:00', pool: 'A', court: 'Court 1',score:"GCC 3:1 Cotton"  },
  { date: '25/10/24', match: 'NERIST vs GCC', time: '14:00-15:30', pool: 'A', court: 'Court 1' ,score:"NERIST 3:1 GCC" },
  { date: '25/10/24', match: 'TISS vs AEC', time: '14:00-15:30', pool: 'B', court: 'Court 2' ,score:"TISS 1:3 AEC" },
  { date: '25/10/24', match: 'GEC Vaishali vs Cotton', time: '15:30-17:00', pool: 'A', court: 'Court 1',score:"GEC Vaishali 3:0 Cotton"  },
  { date: '25/10/24', match: 'Tetso vs IITG', time: '17:00-18:30', pool: 'B', court: 'Court 1',score:"Tetso 0:3 IITG"  },
  { date: '26/10/24', match: 'TISS vs IITG', time: '9:00-10:30', pool: 'B', court: 'Court 1',score:null  },
  { date: '26/10/24', match: 'TETSO vs AEC', time: '9:00-10:30', pool: 'B', court: 'Court 2',score:null  },
  { date: '26/10/24', match: 'NERIST vs Cotton', time: '10:30-12:00', pool: 'A', court: 'Court 1' ,score:null },
  { date: '26/10/24', match: 'GEC Vaishali vs GCC', time: '10:30-12:00', pool: 'A', court: 'Court 2',score:null  },
  { date: '26/10/24', match: 'AEC vs IITG', time: '14:00-15:30', pool: 'B', court: 'Court 1',score:null  },
  { date: '26/10/24', match: 'Winner of A vs Runner of B', time: '16:30-18:00', pool: 'SF1', court: 'Court 1',score:null  },
  { date: '26/10/24', match: 'Runner of A vs Winner of B', time: '16:30-18:00', pool: 'SF2', court: 'Court 2',score:null  },
  { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '9:00-10:30', pool: 'Final', court: 'Court 1',score:null  }
  ],
  'Table Tennis (Women)':[
  { date: '25/10/24', match: 'AIPE vs LNIPE', time: '10:30-11:30', pool: 'A', court: 'Court 2',score:"AIPE got walkover"  },
  { date: '25/10/24', match: 'LNIPE vs IITG', time: '17:00-18:00', pool: 'A', court: 'Court 2',score:"IITG got walkover"  },
  { date: '26/10/24', match: 'IITG vs AIPE', time: '14:00-15:30', pool: 'A', court: 'Court 2',score:null  },
  { date: '27/10/24', match: 'Winner of Pool vs Runner of Pool', time: '9:00-10:30', pool: 'Final', court: 'Court 2',score:null  }
  ],
  'Cricket (Men)':[
        // Qualifiers - 24/10/2024
        { date: '24/10/24', match: 'Heritage vs NIT Silchar', time: '9:00-11:00', pool: 'Qualifier1', court: '' ,score:'74/3(10.0ov)---41/9(10.0ov) Heritage cricket won by 33 runs'},
        { date: '24/10/24', match: 'S.B. Deorah vs Dispur College', time: '11:00-13:00', pool: 'Qualifier2', court: '',score:'29/2(5.0ov)---31/8 (10.0ov) SB won by ACA rules' },
        { date: '24/10/24', match: 'LNIPE vs BBEC', time: '15:00-17:00', pool: 'Qualifier3', court: '' ,score:'34/6(5.0ov)--33/3(5.0ov) LNIPE won by 4 wickets'},
      
        // League Matches - 25/10/2024
        { date: '25/10/24', match: 'IITG Alum vs LNIPE', time: '8:00-10:30', pool: 'B', court: '' ,score:"60/5(5.0ov)---25/6(5.0ov) LNIPE won by 35 runs"},
        { date: '25/10/24', match: 'DSEU vs Qualifier1 Winner', time: '10:30-13:00', pool: 'A', court: '' },
        { date: '25/10/24', match: 'Qualifier2 Winner vs LNIPE', time: '13:30-16:00', pool: 'B', court: '' },
      
        // League Matches - 26/10/2024
        { date: '26/10/24', match: 'IITG vs DSEU', time: '8:00-10:30', pool: 'A', court: '' },
        { date: '26/10/24', match: 'IITG Alum vs Qualifier2 Winner', time: '10:30-13:00', pool: 'B', court: '' },
        { date: '26/10/24', match: 'IITG vs Qualifier1 Winner', time: '13:30-16:00', pool: 'A', court: '' },
      
        // Knockout Matches - 27/10/2024
        { date: '27/10/24', match: 'Winner of A vs Runner of B', time: '8:00-10:00', pool: 'SF1', court: '' },
        { date: '27/10/24', match: 'Runner of B vs Winner of C', time: '10:00-12:00', pool: 'SF2', court: '' },
        { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '12:30-16:00', pool: 'FINAL', court: '' }
  ],
  'Hockey (Men)':[
        // Day 1 - 25/10/2024
        { date: '25/10/24', match: 'Missa Hockey Academy vs Bhetapara Hockey Stadium', time: '9:00-10:20', pool: 'A', court: '',score:'Bhetapara hockey stadium 0 : 2 Missa hockey academy' },
        { date: '25/10/24', match: 'Rajagaon hockey academy vs Bongaigaon college', time: '10:20-11:40', pool: 'B', court: '' },
        { date: '25/10/24', match: 'Bhetapara Hockey Stadium vs AF Stn Digaru, Sonapur', time: '11:40-13:00', pool: 'A', court: '' },
        { date: '25/10/24', match: 'AF Stn Digaru, Sonapur vs Missa Hockey Academy', time: '15:50-17:10', pool: 'A', court: '' },
        { date: '25/10/24', match: 'Bongaigaon college vs IIT Guwahati', time: '17:10-18:30', pool: 'B', court: '' },
      
        // Day 2 - 26/10/2024
        { date: '26/10/24', match: 'IIT Guwahati vs Rajagaon hockey academy', time: '9:00-10:20', pool: 'B', court: '' },
        { date: '26/10/24', match: 'Winner of A vs Runner of B', time: '11:00-12:20', pool: 'SF1', court: '' },
        { date: '26/10/24', match: 'Runner of A vs Winner of A', time: '12:20-13:40', pool: 'SF2', court: '' },
        { date: '26/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '16:00-17:30', pool: 'Final', court: '' }
  ],
  'KhoKho (Men)':[
    { date: '25/10/24', match: 'IIT(BHU) Varanasi vs NIT Silchar', time: '9:00-9:45', pool: 'A', court: '',score:'IIT BHU 15:11 NIT SILCHAR' },
  { date: '25/10/24', match: 'Assam engineering college vs Cotton university', time: '9:45-10:30', pool: 'B', court: '' ,score:"Cotton 11:4 AEC"},
  { date: '25/10/24', match: 'NERIST vs Amity University Noida', time: '11:15-12:00', pool: 'A', court: '',score:"NERIST 21:23 Amity" },
  { date: '25/10/24', match: 'Cotton university vs B.Borooah college', time: '13:30-14:15', pool: 'B', court: '',score:"Cotton 13:6 B.Borooah" },
  { date: '25/10/24', match: 'IIT(BHU) Varanasi vs Amity University Noida', time: '15:00-15:45', pool: 'A', court: '' },
  { date: '25/10/24', match: 'B.Borooah college vs Assam engineering college', time: '15:45-16:30', pool: 'B', court: '' },

  // Day 2 - 26/10/24
  { date: '26/10/24', match: 'Assam engineering college vs IIT Guwahati', time: '9:00-9:45', pool: 'B', court: '' },
  { date: '26/10/24', match: 'NIT Silchar vs NERIST', time: '9:45-10:30', pool: 'A', court: '' },
  { date: '26/10/24', match: 'Cotton university vs IIT Guwahati', time: '11:15-12:00', pool: 'B', court: '' },
  { date: '26/10/24', match: 'IIT(BHU) Varanasi vs NERIST', time: '14:15-15:00', pool: 'B', court: '' },
  { date: '26/10/24', match: 'B.Borooah college vs IIT Guwahati', time: '15:00-15:45', pool: 'B', court: '' },
  { date: '26/10/24', match: 'NIT Silchar vs Amity University Noida', time: '15:45-16:30', pool: 'A', court: '' },

  // Day 3 - 27/10/24
  { date: '27/10/24', match: 'Winner of A vs Runner of B', time: '10:30-11:15', pool: 'SF1', court: '' },
  { date: '27/10/24', match: 'Runner of A vs Winner of B', time: '11:15-12:00', pool: 'SF2', court: '' },
  { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '15:00-16:00', pool: 'Final', court: '' }
  ],
  'KhoKho (Women)':[
    { date: '25/10/24', match: 'Cotton university vs NERIST', time: '14:15-15:00', pool: 'B', court: '',score:"Cotton 7:5 NERIST" },
  { date: '25/10/24', match: 'Amity University Noida vs Handique Girls College', time: '16:30-17:15', pool: 'A', court: '',score:"Amity 12:2 Handique" },
  { date: '25/10/24', match: 'Cotton university vs IIT(BHU) Varanasi', time: '10:30-11:15', pool: 'A', court: '' },

  // Day 2 - 26/10/24
  { date: '26/10/24', match: 'NERIST vs IIT(BHU) Varanasi', time: '10:30-11:15', pool: 'A', court: '' },
  { date: '26/10/24', match: 'Handique Girls College vs IIT Guwahati', time: '13:30-14:15', pool: 'B', court: '' },
  { date: '26/10/24', match: 'IIT Guwahati vs Amity University Noida', time: '16:30-17:15', pool: 'B', court: '' },

  // Day 3 - 27/10/24
  { date: '27/10/24', match: 'Winner of A vs Runner of B', time: '9:00-9:45', pool: 'SF1', court: '' },
  { date: '27/10/24', match: 'Runner of A vs Winner of B', time: '9:45-10:30', pool: 'SF2', court: '' },
  { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '14:00-15:00', pool: 'Final', court: '' }
  ],
  'Volleyball (Women)':[
    { date: '25/10/24', match: 'AIPE vs ADBU Tupesia', time: '8:00-9:00', pool: 'A', court: '3' },
    { date: '25/10/24', match: 'IIT Patna vs Shenbitse', time: '9:00-10:00', pool: 'B', court: '3' },
    { date: '25/10/24', match: 'Cotton vs ADBU Azara', time: '10:00-11:00', pool: 'C', court: '3' },
    { date: '25/10/24', match: 'Lakhimpur vs ADTU', time: '11:00-12:00', pool: 'D', court: '3' },
    { date: '25/10/24', match: 'ADBU Tupesia vs AEC', time: '15:00-16:00', pool: 'A', court: '3' },
    { date: '25/10/24', match: 'Shenbitse vs BBEC', time: '16:00-17:00', pool: 'B', court: '3' },
    { date: '25/10/24', match: 'ADBU Azara vs DSEU', time: '17:00-18:00', pool: 'C', court: '3' },
    { date: '25/10/24', match: 'ADTU vs IITG', time: '18:00-19:00', pool: 'D', court: '3' },
    { date: '25/10/24', match: 'AEC vs AIPE', time: '19:00-20:00', pool: 'A', court: '3' },
  
    // Day 2 - 26/10/2024 - League & Quarter Finals
    { date: '26/10/24', match: 'BBEC vs IIT Patna', time: '8:00-9:00', pool: 'B', court: '3' },
    { date: '26/10/24', match: 'IITG vs Lakhimpur', time: '9:00-10:00', pool: 'D', court: '3' },
    { date: '26/10/24', match: 'DSEU vs Cotton', time: '10:00-11:00', pool: 'C', court: '3' },
    { date: '26/10/24', match: 'Winner of A vs Runner of C', time: '15:00-16:00', pool: 'QF1', court: '3' },
    { date: '26/10/24', match: 'Winner of B vs Runner of D', time: '16:00-17:00', pool: 'QF2', court: '3' },
    { date: '26/10/24', match: 'Runner of A vs Winner of C', time: '17:00-18:00', pool: 'QF3', court: '3' },
    { date: '26/10/24', match: 'Runner of B vs Winner of D', time: '18:00-19:00', pool: 'QF4', court: '3' },
  
    // Day 3 - 27/10/2024 - Semi Finals & Final
    { date: '27/10/24', match: 'Winner of QF1 vs Winner of QF4', time: '8:00-9:00', pool: 'SF1', court: '3' },
    { date: '27/10/24', match: 'Winner of QF2 vs Winner of QF3', time: '9:00-10:00', pool: 'SF2', court: '3' },
    { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '14:00-16:00', pool: 'FINAL', court: '3' }
  ],
  'Basketball (Men)':[
    { date: '25/10/24', match: 'BBEC vs AKU', time: '9:00-10:00', pool: 'MEN', court: '1' },
  { date: '25/10/24', match: 'Sherubtse(Bhutan) vs TETSO', time: '9:00-10:00', pool: 'MEN', court: '2',score:'SHERUBTSE 26:25 TETSO WARRIORS' },
  { date: '25/10/24', match: 'Dibrugarh vs RGU', time: '10:00-11:00', pool: 'MEN', court: '1' ,score:"Dibrugarh 61:43 RGU"},
  { date: '25/10/24', match: 'AEC vs NERIST', time: '10:00-11:00', pool: 'MEN', court: '2',score:"AEC 25:37 NERIST" },
  { date: '25/10/24', match: 'IIT Patna vs ADBU Tupesia', time: '14:00-15:00', pool: 'MEN', court: '1',score:"IIT Patna 47:54 ADBU Tupesia " },
  { date: '25/10/24', match: 'ADBU Azara vs Jagiroad', time: '14:00-15:00', pool: 'MEN', court: '2' },
  { date: '25/10/24', match: 'DSEU vs GU', time: '16:00-17:00', pool: 'MEN', court: '1' },
  { date: '25/10/24', match: 'IITG vs COTTON', time: '18:00-19:00', pool: 'MEN', court: '1' },

  // Day 2 - 26/10/2024 - Playoffs
  { date: '26/10/24', match: 'Winner of B vs Runner of D', time: '8:00-9:00', pool: 'PQ2', court: '1' },
  { date: '26/10/24', match: 'Winner of A vs Runner of C', time: '8:00-9:00', pool: 'PQ1', court: '2' },
  { date: '26/10/24', match: 'Runner of B vs Winner of D', time: '9:00-10:00', pool: 'PQ3', court: '1' },
  { date: '26/10/24', match: 'Runner of A vs Winner of C', time: '9:00-10:00', pool: 'PQ3', court: '2' },
  { date: '26/10/24', match: 'Winner of F vs Runner of H', time: '10:00-11:00', pool: 'PQ6', court: '1' },
  { date: '26/10/24', match: 'Winner of E vs Runner of G', time: '10:00-11:00', pool: 'PQ5', court: '2' },
  { date: '26/10/24', match: 'Runner of F vs Winner of H', time: '11:00-12:00', pool: 'PQ8', court: '1' },
  { date: '26/10/24', match: 'Runner of E vs Winner of G', time: '11:00-12:00', pool: 'PQ7', court: '2' },
  { date: '26/10/24', match: 'Winner of PQ1 vs Winner of PQ4', time: '3:00-4:00', pool: 'QF1', court: '1' },
  { date: '26/10/24', match: 'Winner of PQ2 vs Winner of PQ3', time: '4:00-5:00', pool: 'QF2', court: '1' },

  // Day 3 - 27/10/2024 - Semifinals & Final
  { date: '27/10/24', match: 'Winner of QF1 vs Winner of QF4', time: '10:00-11:00', pool: 'SF1', court: '1' },
  { date: '27/10/24', match: 'Winner of QF2 vs Winner of QF3', time: '10:00-11:00', pool: 'SF2', court: '2' },
  { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '15:00-16:00', pool: 'Final', court: '1' }
  ],
  'Basketball (Women)':[
    { date: '25/10/24', match: 'DSEU vs RGU', time: '8:00-9:00', pool: 'WOMEN', court: '1' ,score:"DSEU 7:18 RGU"},
  { date: '25/10/24', match: 'ADBU Azara vs IIT Patna', time: '8:00-9:00', pool: 'WOMEN', court: '2' ,score:"IIT PATNA 4:19 ADBU Azara"},
  { date: '25/10/24', match: 'Jagiroad vs Shenbitse', time: '11:00-12:00', pool: 'WOMEN', court: '1' },
  { date: '25/10/24', match: 'IIT Patna vs ADBU Tupesia', time: '11:00-12:00', pool: 'WOMEN', court: '2',score:"IIT Patna 6:19 ADBU Tupesia" },
  { date: '25/10/24', match: 'DSEU vs Jagiroad', time: '15:00-16:00', pool: 'WOMEN', court: '1' },
  { date: '25/10/24', match: 'ADBU Tupesia vs ADBU Azara', time: '15:00-16:00', pool: 'WOMEN', court: '2' },
  { date: '25/10/24', match: 'ADBU Azara vs IITG', time: '17:00-18:00', pool: 'WOMEN', court: '1' },
  { date: '25/10/24', match: 'RGU vs Shenbitse', time: '17:00-18:00', pool: 'WOMEN', court: '2' },

  // Day 2 - 26/10/2024
  { date: '26/10/24', match: 'IIT Patna vs IITG', time: '2:00-3:00', pool: 'WOMEN', court: '1' },
  { date: '26/10/24', match: 'RGU vs Jagiroad', time: '2:00-3:00', pool: 'WOMEN', court: '2' },
  { date: '26/10/24', match: 'ADBU Tupesia vs IITG', time: '5:00-6:00', pool: 'WOMEN', court: '1' },
  { date: '26/10/24', match: 'DSEU vs Shenbitse', time: '5:00-6:00', pool: 'WOMEN', court: '2' },

  // Day 3 - 27/10/2024 - Semifinals & Final
  { date: '27/10/24', match: 'Runner of A vs Winner of B', time: '9:00-10:00', pool: 'SF2', court: '1' },
  { date: '27/10/24', match: 'Winner of A vs Runner of B', time: '9:00-10:00', pool: 'SF1', court: '2' },
  { date: '27/10/24', match: 'Winner of SF1 vs Winner of SF2', time: '14:00-15:00', pool: 'Final', court: '1' }
  ],
  'Football (Men)':[
        {
          "date": "25/10/24",
          "match": "IIIT Guwahati vs Cotton",
          "time": "8:00-9:00",
          "pool": "D",
          "score":"Cotton 1:0  IIIT GUWAHATI "
        },
        {
          "date": "25/10/24",
          "match": "BBEC vs TETSO",
          "time": "9:00-10:00",
          "pool": "B", 
          "score":"BBEC 1:3 TETSO"

        },
        {
          "date": "25/10/24",
          "match": "GMC vs ADBU Azara",
          "time": "10:00-11:00",
          "pool": "C",
          "score":"ADBU AZARA 3 : 0 GMC "

        },
        {
          "date": "25/10/24",
          "match": "AEC vs AIPE",
          "time": "11:00-12:00",
          "pool": "A",
          "score":"AEC 1 : 3 AIPE "

        },
        {
          "date": "25/10/24",
          "match": "Cotton vs Sherubise",
          "time": "12:00-13:00",
          "pool": "D",
          "score":"Cotton 0 : 1 Sherubtse"

        },
        {
          "date": "25/10/24",
          "match": "TETSO vs RGU",
          "time": "15:00-16:00",
          "pool": "B",
          "score":"RGU 2 : 0 TETSO "

        },
        {
          "date": "25/10/24",
          "match": "ADBU Azara vs ADBU Tapesia",
          "time": "16:00-17:00",
          "pool": "C",
          "score":"ADBU Azara 0 : 2 ADBU Tapesia "

        },
        {
          "date": "25/10/24",
          "match": "Sherubise vs IIIT Guwahati",
          "time": "17:00-18:00",
          "pool": "D",
          "score":"Sherubtse 3 : 0 IIIT GUWAHATI "

        },
        {
          "date": "25/10/24",
          "match": "AIPE vs IITG",
          "time": "18:00-19:00",
          "pool": "A",
          "score":"IITG 0 : 0 AIPE "

        },
        {
          "date": "26/10/24",
          "match": "IITG vs AEC",
          "time": "8:00-9:00",
          "pool": "A",

        },
        {
          "date": "26/10/24",
          "match": "ADBU Tapesia vs GMC",
          "time": "9:00-10:00", 
          "pool": "C",

        },
        {
          "date": "26/10/24",
          "match": "RGU vs BBEC",
          "time": "10:00-11:00",
          "pool": "B",

        },
        {
          "date": "26/10/24",
          "match": "Winner of A vs Runner of C",
          "time": "14:00-15:00",
          "pool": "QF1",

        },
        {
          "date": "26/10/24",
          "match": "Winner of B vs Runner of D",
          "time": "15:00-16:00",
          "pool": "QF2",

        },
        {
          "date": "26/10/24",
          "match": "Runner of A vs Winner of C",
          "time": "16:00-17:00",
          "pool": "QF3",

        },
        {
          "date": "26/10/24",
          "match": "Runner of B vs Winner of D",
          "time": "17:00-18:00",
          "pool": "QF4",
        },
        {
          "date": "27/10/24",
          "match": "Winner of QF1 vs Winner of QF4",
          "time": "9:00-10:00",
          "pool": "SF1",

        },
        {
          "date": "27/10/24",
          "match": "Winner of QF2 vs Winner of QF3",
          "time": "10:00-11:00",
          "pool": "SF2",

        },
        {
          "date": "27/10/24",
          "match": "Winner of SF1 vs Winner of SF2",
          "time": "14:00-15:00",
          "pool": "FINAL",
        }
  ]
};

function SportsResults() {
  const [selectedSport, setSelectedSport] = useState("Kabaddi (Men)");

  return (
    <div className="bg-black text-white min-h-screen p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <header className="text-center flex mb-4">
          <div className="flex-col justify-end items-end">
            <h1 className="text-3xl font-bold">SPIRIT RESULTS</h1>
            <h2 className="text-3xl font-bold">BROUGHT</h2>
            <h2 className="text-3xl font-bold">TO YOU BY </h2>
            <h2 className="text-3xl font-bold">DASH </h2>
          </div>
          <img
            src="mascot.png"
            alt="Spirit News Logo"
            className="w-32 h-32 lg:w-48 lg:h-48 object-contain animate-bounce hover:scale-110 transition-transform duration-500"
          />
        </header>
        <div>
          <select
            className="bg-gray-800 p-2 rounded-md text-white"
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
          >
            {Object.keys(teamLimits).map((sport) => (
              <option key={sport} value={sport}>
                {sport}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Display Fixtures */}
      <div className="space-y-8">
        {fixtures[selectedSport] && fixtures[selectedSport].map((fixture, index) => (
          <div key={index} className="p-6 bg-gray-900 rounded-md">
            <h2 className="text-2xl font-semibold mb-4">Match {index+1}</h2>
            <div className="bg-gray-800 p-4 rounded-md">
              <p className="text-xl">{fixture.match}</p>
              <p className="text-sm">Time: {fixture.time}</p>
              <p className="text-sm">Pool: {fixture.pool}</p>
              {fixture.court && <p className="text-sm">Court: {fixture.court}</p>}
              <div className="mt-4">
                <label className="text-sm">Final Score:</label>
                <p
  type="text"
  className="bg-gray-700 p-2 rounded-md ml-2 text-white text-sm sm:text-xs md:text-sm lg:text-base"
>{fixture.score || ""}</p>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SportsResults;
