import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Navbar from './navbar';
import Footer from './footer';
import FAQSection from './Faq';
import { Instagram, Facebook, Youtube } from 'lucide-react';
import Slider from 'react-slick'; // Importing react-slick

const SpiritFestival = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const stats = [
    { number: '30+', label: 'Events' },
    { number: '2000+', label: 'Participants' },
    { number: '100+', label: 'Colleges' },
    { number: '40K+', label: 'Footfall' },
  ];

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const carouselImages = ['Image1.png', 'Image2.png', 'Image2.jpg']; // List of carousel images

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Default for large screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // 2 seconds
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768, // At 768px or smaller (tablet and mobile)
        settings: {
          slidesToShow: 1, // Show 1 slide for small screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // At 1024px or smaller (small desktops or larger tablets)
        settings: {
          slidesToShow: 2, // Show 2 slides for medium to large screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Navbar />
      {/* Main Content */}
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-[#141414] p-4 rounded-lg">
          <section
            className="overflow-hidden bg-center bg-no-repeat text-white p-16 md:p-126 lg:p-44 rounded-2xl flex flex-col items-center justify-center relative"
            style={{
              backgroundImage: `url('Landing.png')`,
              backgroundSize: 'cover', // Ensure full cover
              backgroundPosition: 'center',
              backgroundColor: 'transparent',
              filter: 'brightness(1) contrast(1) saturate(1.1)', // Adjust values to increase vibrancy
            }}
          >
 <div className="text-center z-10 px-4">
  <h1 className="text-2xl md:text-3xl lg:text-4xl font-fireSans uppercase my-8">
    Participate in <br />
    the Largest Sports Festival of <br />
    <span className="">North - East India</span>
  </h1>

  <div className="flex gap-4 justify-center mt-4">
    <a
      href="/login"
      className="bg-transparent border-2 border-white px-6 py-2 rounded-2xl text-white font-semibold text-lg hover:bg-white hover:text-[#141414] transition duration-300 md:px-4 md:py:2 md:text-sm"
    >
      Login
    </a>
  </div>
</div>


          </section>
        </div>

        {/* Statistics */}
        <div className="bg-[#141414] text-white p-4 md:p-8">
          {/* Image Grid Section */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
  <div className="lg:col-span-2"> {/* Left column (2/3rd) on large screens, full-width on small screens */}
    {/* Carousel Container */}
    <Slider {...settings}>
      {carouselImages.map((image, index) => (
        <div key={index} className="relative">
          <img
            src={image}
            alt={`Event ${index}`}
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
      ))}
    </Slider>
  </div>

  <div className="lg:col-span-1 bg-[#141414] border border-white rounded-lg flex flex-col items-center justify-center p-8 text-center"> 
  {/* Right column (1/3rd on large screens, full-width on small screens) */}
  <h2 className="text-2xl md:text-3xl font-bold leading-tight mb-4">
    Meet the<br />Mascot
  </h2>
  <img 
    src="mascot.png" 
    alt="Spirit Mascot" 
    className="w-32 h-32 lg:w-48 lg:h-48 object-contain animate-bounce transition-all duration-300 hover:scale-110" 
  />
</div>

</div>




          {/* Statistics Section */}
          <div className="bg-[#141414] border font-leagueGothic border-white rounded-lg p-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
              {stats.map((stat, index) => (
                <div key={index} className="space-y-2">
                  <div className="text-6xl md:text-8xl font-bold">{stat.number}</div>
                  <div className="text-sm md:text-base lg:text-xl">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <FAQSection />

        {/* Carousel Section */}
        <section className="p-4 relative rounded-lg overflow-hidden max-w-5xl mx-auto">  {/* Increase max width */}
  <iframe
    className="w-full h-96"
    src="https://www.youtube.com/embed/QdWPh--ynE4"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</section>

      </main>

      {/* Footer */}
      {/* <Footer /> */}
    </div>
  );
};

export default SpiritFestival;
