import Navbar from "../components/navbar";
import NewsComponent from "../components/news";
import Footer from "../components/footer";

const Auth=()=>{
    return (
        <div>
            <Navbar/>
            <NewsComponent/>
            <Footer/>
        </div>
    )
}
export default Auth;