import LoginComponent from "../components/login";
import Footer from "../components/footer";

const Auth=()=>{
    return (
        <div>
            <LoginComponent/>
        </div>
    )
}
export default Auth;