import React from 'react';

const NewsComponent = () => {
  return (
    <div className="bg-[#141414] text-white p-4">
      <header className="text-center flex mb-4">
        <div className='flex-col justify-end items-end'>
        <h1 className="text-3xl font-bold">SPIRIT NEWS</h1>
        <h2 className="text-3xl font-bold">BROUGHT</h2>
        <h2 className="text-3xl font-bold">TO YOU BY </h2>
        <h2 className="text-3xl font-bold">DASH </h2>
        </div>
        <img 
            src="mascot.png" 
            alt="Spirit News Logo"
            className="w-32 h-32 lg:w-48 lg:h-48 object-contain"
          />
      </header>

      <div className="grid lg:grid-cols-3 gap-6">
        {/* Main News Section */}
        <div className="lg:col-span-2">
          <div className="bg-[#141414] p-6 rounded-lg mb-6">
            <div className="flex items-center mb-2">
              <span className="bg-orange-600 text-white px-2 py-1 rounded-full text-sm mr-2">Basketball</span>
              <span className="text-gray-400 text-sm">2 min ago</span>
            </div>
            <h1 className="text-3xl font-bold mb-4">Women’s Basketball Semifinal Results</h1>
            <p className="text-gray-300 mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id leo in libero condimentum laoreet consectetur sed ante...
            </p>
            <div className="bg-gray-700 h-48 rounded-lg"></div> {/* Placeholder for the image */}
          </div>
        </div>

        {/* Side News Section */}
        <div className="space-y-6">
          {[1, 2, 3].map((item) => (
            <div className="bg-[#141414] p-6 rounded-lg" key={item}>
              <div className="flex items-center mb-2">
                <span className="bg-orange-600 text-white px-2 py-1 rounded-full text-sm mr-2">Weightlifting</span>
                <span className="text-gray-400 text-sm">2 min ago</span>
              </div>
              <h1 className="text-xl font-bold mb-2">What events are being held?</h1>
              <p className="text-gray-300">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id leo in libero condimentum...
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsComponent;
