import ProfileComponent from "../components/profile";


const Auth=()=>{
    return (
        <div>
            <ProfileComponent/>
        </div>
    )
}
export default Auth;